:root{
  --primary-color:rgb(11,78,179);
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

*,
*::after,
*::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*General Settings*/
label{
  display: block;
  margin-bottom: 0.5rem;
}

input{
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.text-center{
  text-align: center;
  color: #2c3e50;
}

.with-50{
  width: 50%;
}

.ml-auto{
  margin-left: auto;
}

/*Progress*/
.progressbar{
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 4rem 0;
  counter-reset: step;
}

.progressbar::before, .progress{
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  background-color: #dcdcdc;
}

.progress{
  background-color: var(--primary-color);
  width: 0%;
  transition: 0.3s ease-in-out;
}

.progress-step{
  width: 35px;
  height: 35px;
  background-color: #dcdcdc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.progress-step::before{
  counter-increment: step;
  content: counter(step);
}

.progress-step::after{
  content: attr(data-title);
  position: absolute;
  top: calc(100% + 0.5rem);
  font-size: .80rem;
  color: #666666;
}

.progress-step.active{
  background-color: var(--primary-color);
  color: #f3f3f3;
}

/*form*/
.margenes {
  margin-top: 1em;
  margin-bottom: 1em;
}
/*form*/
.form{
  width: 35em;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: .35rem;
  padding: 1.5rem;
  background-color: #ecf0f1;
  box-shadow: 2px 4px 11px -1px #000000;
}
@media (max-width: 600px) {
  .form {
    width: 20em;
  }
}
.form-step{
  display: none;
  transform-origin: top;
  animation: animate 0.5s;
}

@keyframes animate {
  from{
      transform: scale(1, 0);
      opacity: 0;
  }
  to{
      transform: scale(1, 1);
      opacity: 1;
  }
}

.form-step.active{
  display: block;
}

.input-group{
  margin: 2rem 0;
}

/*Buttons*/
.btns-group{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 1.5rem;
}
.btn{
  padding: 0.75rem;
  display: block;
  text-align: center;
  text-decoration: none;
  background-color: var(--primary-color);
  color: #f3f3f3;
  border-radius: .25rem;
  cursor: pointer;
  transition: all 250ms ease-in-out;
}

.btn:active{
  transform: scale(.98);
}

.btn:hover{
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px var(--primary-color);
}

/* Estilos para el elemento select */
select {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Estilo para el texto dentro del elemento select */
option {
  font-size: 16px;
}

/* Estilo para el elemento select cuando está en foco */
select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
/*poup*/
.btn5{
  padding: 10px 60px;
  background: #fff;
  border: 0;
  outline: none;
  cursor: pointer;
  font-size: 22px;
  font-weight: 500;
  border-radius: 10px;
  transition: transform 0.2s;
}

.btn5:active{
  transform: scale(0.95);
}

.popup{
  width: 400px;
  background: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.1);
  text-align: center;
  padding: 0 30px 60px;
  color: #333;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}

.open-popup{
  visibility: visible;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
}

.popup img{
  width: 100px;
  margin-top: -50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.popup h2{
  font-size: 38px;
  font-weight: 500;
  margin: 30px 0 10px;
}

.popup button{
  width: 100%;
  margin-top: 50px;
  padding: 10px 0;
  background-color: #e02189;
  color: #fff;
  border: 0;
  outline: none;
  font-size: 18px;
  border-radius: 4px;
  box-shadow: 0 5px 5px rgba(0,0,0,0.2);
}
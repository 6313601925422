@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: grid;
  place-items: center;
  min-height: 100vh;
  background: linear-gradient(to right, #74ebd5,#acb6e5);
}

code {
  font-family: 'Montserrat',sans-serif;
}
